import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

class Gallery extends React.Component {
  constructor (props) {
    super(props)
    this.state = { currentId: 1 }
    this.handleClickGalleryItem = this.handleClickGalleryItem.bind(this)
  }

  handleClickGalleryItem(id) {
    this.setState({
      currentId: id
    })
  }

  getCurrentItem() {
    return this.props.sliderItems.find(item => item.id === this.state.currentId)
  }

  renderImgDesc() {
    if(!this.getCurrentItem().desc) return
    return (
      <div className={`${this.props.baseClass}-img-desc`}>
        {this.getCurrentItem().desc}
      </div>
    )
  }

  render() {
    return (
      <React.Fragment>
        <figure
          className={`${this.props.baseClass}-img-holder`}
          style={{
            backgroundImage: `url(${this.getCurrentItem().url})`
          }}>
          {this.renderImgDesc()}
        </figure>
        <Slider {...this.props.sliderOptions}>
          {this.props.sliderItems.map(item => (
            <div
              className={`${this.props.baseClass}-item`}
              key={item.id}
              onClick={() => this.handleClickGalleryItem(item.id)}>
              <div
                className={`${this.props.baseClass}-item-img`}
                style={{
                  backgroundImage: `url(${item.url})`
                }}>
              </div>
            </div>
          ))}
        </Slider>
      </React.Fragment>
    )
  }
}

Gallery.propTypes = {
  sliderItems: PropTypes.array.isRequired,
  sliderOptions: PropTypes.object,
  baseClass: PropTypes.string.isRequired
}

Gallery.defaultProps = {
  sliderOptions: {
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: 3,
    slidesToScroll: 3
  }
}

export default Gallery
