import React from 'react'
import { withTranslation } from 'react-i18next'

import I18n from '../components/i18n'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Gallery from '../components/gallery'

const ProcessExportingPage = ({ t }) => {
  return (
    <Layout>
      <SEO title={t('process_exporting.title')} keywords={[`agriculture`, `cultivate`, `japan`, `vietnam`, `pepper`]} />
			<div className="page">
				<div className="container grid-lg">
					<h1 className="page-title" dangerouslySetInnerHTML={{ __html: t('process_exporting.title') }}></h1>

          <div className="columns">
            <div className="column col-5 col-lg-12 section-content">
              <h2 className="page-subtitle">1. {t('process_exporting.step1')}</h2>
            </div>
            <div className="column col-7 col-lg-12">
							<div className="img-as-bg" style={{
								backgroundImage: `url(${require('../images/process_exporting1_1.jpg')})`
							}}></div>
            </div>
          </div>

          <div className="columns">
            <div className="column col-5 col-lg-12 section-content">
              <h2 className="page-subtitle">2. {t('process_exporting.step2')}</h2>
            </div>
            <div className="column col-7 col-lg-12">
							<div className="img-as-bg" style={{
								backgroundImage: `url(${require('../images/process_exporting2_1.jpg')})`
							}}></div>
            </div>
          </div>

          <div className="columns">
            <div className="column col-5 col-lg-12 section-content">
              <h2 className="page-subtitle">3. {t('process_exporting.step3')}</h2>
            </div>
            <div className="column col-7 col-lg-12">
              <Gallery baseClass="import" sliderItems={[
                { id: 1, url: require('../images/process_exporting3_1.jpg') },
                { id: 2, url: require('../images/process_exporting3_2.jpg') },
                { id: 3, url: require('../images/process_exporting3_3.jpg') }
              ]}/>
            </div>
          </div>

          <div className="columns">
            <div className="column col-5 col-lg-12 section-content">
              <h2 className="page-subtitle">4. {t('process_exporting.step4')}</h2>
            </div>
            <div className="column col-7 col-lg-12">
              <Gallery
                baseClass="import"
                sliderOptions={{
                  slidesToShow: 2,
                  slidesToScroll: 2,
                }}
                sliderItems={[
                  { id: 1, url: require('../images/process_exporting4_1.jpg') },
                  { id: 2, url: require('../images/process_exporting4_2.png') },
                ]}
               />
            </div>
          </div>

          <div className="columns">
            <div className="column col-5 col-lg-12 section-content">
              <h2 className="page-subtitle">5. {t('process_exporting.step5')}</h2>
            </div>
            <div className="column col-7 col-lg-12">
              <Gallery baseClass="import" sliderItems={[
                { id: 1, url: require('../images/process_exporting5_1.jpg') },
                { id: 2, url: require('../images/process_exporting5_2.jpg') },
                { id: 3, url: require('../images/process_exporting5_3.png') },
              ]}/>
            </div>
          </div>

          <div className="columns">
            <div className="column col-5 col-lg-12 section-content">
              <h2 className="page-subtitle">6. {t('process_exporting.step6')}</h2>
            </div>
            <div className="column col-7 col-lg-12">
              <Gallery
                baseClass="import"
                sliderOptions={{
                  slidesToShow: 2,
                  slidesToScroll: 2,
                }}
                sliderItems={[
                  { id: 1, url: require('../images/process_exporting6_1.jpg') },
                  { id: 2, url: require('../images/process_exporting6_2.jpg') },
                ]}
               />
            </div>
          </div>
        </div>
			</div>
    </Layout>
  )
}

export default withTranslation()(ProcessExportingPage)
